import Keycloak from 'keycloak-js';
import { keycloakConfig } from '../util/constants';
import { Cookies } from 'react-cookie';
import { NO_AUTH_ROUTES } from './no-auth-routes';

const KEYCLOAK_URL = process.env.KEYCLOAK_URL;

if (!KEYCLOAK_URL) {
  throw new Error('KEYCLOAK_URL not defined');
}

console.log(
  'KEYCLOAK:',
  KEYCLOAK_URL,
  keycloakConfig.realm,
  keycloakConfig.resource,
);

const keycloak = new Keycloak({
  url: KEYCLOAK_URL + '/auth',
  realm: keycloakConfig.realm,
  clientId: keycloakConfig.resource,
});

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const pathname = window.location.pathname;

const initKeycloak = onAuthenticated => {
  keycloak
    .init({
      onLoad: 'check-sso',
    })
    .then(authenticated => {
      if (authenticated) {
        const cookies = new Cookies();
        var o = new Object();
        var decoded = keycloak.tokenParsed;
        localStorage.setItem('tkn-access', keycloak.token);
        localStorage.setItem('tkn-refresh', keycloak.refreshToken);
        localStorage.setItem(
          'permission',
          keycloak.tokenParsed.profileBackOffice,
        );
        localStorage.setItem('authenticated', true);
        localStorage.setItem(
          'userCpf',
          keycloak.idTokenParsed.preferred_username,
        );
        localStorage.setItem('auth-ok', true);

        const roles = decoded.realm_access.roles.filter(
          element =>
            element === 'backoffice-admin' ||
            element === 'analista_credito' ||
            element === 'backoffice-multiplica' ||
            element === 'backoffice-empresa' ||
            element === 'backoffice-consulta' ||
            element === 'usuario_master' ||
            element === 'consulta_mercado' ||
            element === 'correntista_operador' ||
            element === 'correntista_administrador',
        );

        cookies.set('roles', roles, { path: '/backoffice' });
        cookies.set('name', o.name, { path: '/backoffice' });
        cookies.set('tkn-access', keycloak.token, {
          path: '/backoffice',
        });
        localStorage.setItem('name', o.name);
        localStorage.setItem('nome', decoded.name);
        localStorage.setItem('id-cliente', decoded.id);
        localStorage.setItem('roles', roles);
        localStorage.setItem('profileBackOffice', decoded.profileBackOffice);
        localStorage.setItem(
          'administradorEscrow',
          decoded.administradorEscrow,
        );

        const expiresIn = decoded.exp - decoded.auth_time;
        localStorage.setItem('expiracao-refresh', expiresIn);
        localStorage.setItem('consulta_mercado', decoded.consulta_mercado);
        const tempoExpiracao = expiresIn;
        const horaAtual = new Date();
        horaAtual.setSeconds(horaAtual.getSeconds() + tempoExpiracao);
        localStorage.setItem('horaAgora', horaAtual);
        localStorage.setItem('given_name', decoded.given_name);

        onAuthenticated(authenticated);
      } else if (!NO_AUTH_ROUTES.includes(pathname)) {
        localStorage.setItem('authenticated', false);
        doLogin();
      }
    })
    .catch(() => {
      localStorage.setItem('authenticated', false);
      if (NO_AUTH_ROUTES.includes(pathname)) return;
      doLogout();
    });
};

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  keycloak,
};

export default KeycloakService;
