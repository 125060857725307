import { useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useToast = () => {
  const [themeApp, setThemeApp] = useState(null);

  const useStyles = makeStyles(theme => ({
    customToast: {
      '& .Toastify__toast': {
        background: themeApp?.palette.background.paper,
      },
      '& .Toastify__toast--error': {
        color:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.text.primary
            : themeApp?.palette.error.main,
        background: themeApp?.palette.background.paper,
      },
      '& .Toastify__toast--warning': {
        color:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.text.primary
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& div.Toastify__toast--error div.Toastify__toast-body > div > svg': {
        fill: themeApp?.palette.error.main,
      },
      '& div.Toastify__toast--success div.Toastify__toast-body > div > svg': {
        fill:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.primary.main
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& .Toastify__toast--success': {
        color:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.text.primary
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& .Toastify__toast--warning': {
        color: '#e4a643',
      },
      '& .Toastify__progress-bar--success': {
        background:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.primary.main
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& .Toastify__progress-bar--error': {
        background: themeApp?.palette.error.dark,
      },
      '& .Toastify__progress-bar--warning': {
        background: '#e4a643',
      },
      '& .Toastify__close-button--success': {
        color:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.primary.main
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& .Toastify__close-button--error': {
        color: themeApp?.palette.text.primary,
      },
      '& .Toastify__close-button--warning': {
        color: '#e4a643',
      },
    },
  }));

  return { useStyles, themeApp, setThemeApp };
};

export default useToast;
