import React from 'react';
import { Container } from './styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
const Loading = ({ show }) => {
  return (
    <Container showPopUp={show}>
      <Loader
        type="Rings"
        color="#EEE"
        height={150}
        width={150}
        // timeout={3000} //3 secs
      />
    </Container>
  );
};

export default Loading;
