import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { baseUrl } from '../util/constants';

//const baseUrl = 'https://multisolucoes.grupomultiplica.com.br';
// const baseUrl = 'https://2xcapital.grupomultiplica.com.br';
//const baseUrl = 'https://multipague-hml.grupomultiplica.com.br';

export const api_multipague_confirmEmail = axios.create({
  baseURL: baseUrl + '/ms-conta-ativacao/v1',
});
export const api_multipague_configuracao = axios.create({
  baseURL: baseUrl + '/ms-configuracao/v1',
});
