import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import { Save } from '@material-ui/icons';
import { api_multipague_configuracao } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import { CircularProgress } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="text" align="center">
      © Todos os Direitos Reservados{'. '}
      <Link color="inherit" href="https://grupomultiplica.com.br/">
        Beyond
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: '20px 30px',
    borderRadius: '20px',
    width: '70%',
    margin: '0 15%',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '10% 0',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '80%',
    marginTop: theme.spacing(1),
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

export default function ConfirmEmail(props) {
  const classes = useStyles();
  const [financial, setFinancial] = useState('');
  const [confirmFinancial, setConfirmFinancial] = useState('');
  const [autorizacao, setAutorizacao] = useState('');
  const [loadButton, setLoadButton] = useState(false);
  const [usuario, setUsuario] = useState('');
  const [error, setError] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [isLoadingCpf, setIsLoadingCpf] = useState(false);

  const theme = useTheme();

  const esqueciSenha = () => {
    setIsLoadingCpf(true);
    api_multipague_configuracao
      .post(
        `/usuario/senha/enviar-email?username=${usuario?.replace(
          /[^0-9]+/g,
          '',
        )}`,
      )
      .then(response => {
        setIsLoadingCpf(false);
        toast.success(
          <span>
            {'Um código de confirmação foi enviado para o seu email.'}
          </span>,
          {
            className: 'toast-custom',
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            theme: 'light',
          },
        );
      })
      .catch(error => {
        setIsLoadingCpf(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toast.error(
            <div>
              <span>{error.response.data.mensagem}</span>
            </div>,
            {
              className: 'toast-custom',
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              theme: 'light',
            },
          );
        }
      });
  };

  const options = {
    username: usuario?.replace(/[^0-9]+/g, ''),
    codigoSeguranca: autorizacao,
    senha: financial,
    confirmacaoSenha: confirmFinancial,
  };

  const [values, setValues] = React.useState({
    showPassword: false,
    showPasswordConfirm: false,
    showPasswordConfirmFin: false,
    showPasswordFin: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleClickShowPasswordConfirm = () => {
    setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm });
  };
  const handleMouseDownPasswordConfirm = event => {
    event.preventDefault();
  };

  const handleAbrirConta = () => {
    api_multipague_configuracao
      .post(`/usuario/senha/redefinir`, options)
      .then(response => {
        toast.success(
          <span>{'Ok! Sua senha de acesso foi alterada com sucesso!'}</span>,
          {
            className: 'toast-custom',
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            theme: 'light',
          },
        );
        setTimeout(function () {
          window.location.href = '/login';
        }, 3000);
      })
      .catch(error => {
        console.log(error);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toast.error(<span>{error.response.data.mensagem}</span>, {
            className: 'toast-custom',
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            theme: 'light',
          });
        }
      });
  };

  function check() {
    if (
      document.getElementById('financialpassword').value ==
      document.getElementById('checkfinancialpassword').value
    ) {
      document.getElementById('message').style.color = 'green';
      document.getElementById('message').innerHTML = 'correto';
    } else {
      document.getElementById('message').style.color = 'red';
      document.getElementById('message').innerHTML = 'as senhas não conferem';
    }
  }

  const handleChangeNovaSenha = e => {
    const newValue = e.target.value;

    if (
      newValue.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
      )
    ) {
      setFinancial('');
      setError(false);
      setDisableButton(false);
    } else {
      setError('A senha informada não atende aos requisitos');
      setDisableButton(true);
    }
    setFinancial(newValue);
  };

  useEffect(() => {
    if (usuario.length >= 14) {
      esqueciSenha();
    }
  }, [usuario]);

  return (
    <Container
      component="main"
      maxWidth="md"
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon
            style={{
              color: theme.palette.getContrastText(
                theme.palette.secondary.main,
              ),
            }}
          />
        </Avatar>
        <Typography
          component="h5"
          variant="h5"
          style={{ marginBottom: '15px' }}
        >
          Troca de senha
        </Typography>
        <Typography
          component="body1"
          variant="body1"
          style={{
            fontSize: '14px',
            marginBottom: '15px',
            width: '80%',
          }}
        >
          Confira seu email e digite no campo abaixo o código de autorização que
          você recebeu
        </Typography>
        <form className={classes.form} noValidate>
          <ReactInputMask
            mask="999.999.999-99"
            value={usuario}
            maskChar=""
            onChange={e => {
              setUsuario(e.target.value);
            }}
            error={usuario?.length < 14}
          >
            {() => (
              <TextField
                variant="outlined"
                size="small"
                required
                placeholder="Usuário (CPF)"
                fullWidth
                id="cpf"
                helperText={'Adicione o CPF do usuário para receber o código'}
                name="cpf"
                autoFocus
                InputProps={{
                  style: {
                    borderRadius: '20px',
                  },
                  endAdornment: isLoadingCpf ? (
                    <CircularProgress
                      color="inherit"
                      size={24}
                      circleDisableShrink
                    />
                  ) : null,
                }}
                style={{ marginBottom: '20px', color: 'red' }}
              />
            )}
          </ReactInputMask>
          <TextField
            InputProps={{
              style: {
                borderRadius: '20px',
              },
            }}
            style={{ marginBottom: '20px', color: 'red' }}
            margin="dense"
            variant="outlined"
            size="small"
            inputComponent={TextMaskCustom}
            required
            fullWidth
            placeholder="Código de autorização"
            id="autorizacao"
            name="autorizacao"
            onChange={e => {
              setAutorizacao(e.target.value);
            }}
            autoFocus
          />
          <TextField
            style={{ marginBottom: '20px' }}
            margin="dense"
            InputProps={{
              style: {
                borderRadius: '20px',
              },
            }}
            variant="outlined"
            size="small"
            type={values.showPassword ? 'text' : 'password'}
            required
            fullWidth
            placeholder="Digite a sua nova senha"
            id="financialpassword"
            name="financialpassword"
            error={error}
            helperText={error}
            onBlur={handleChangeNovaSenha}
            onChange={handleChangeNovaSenha}
            autoFocus
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <TextField
            margin="dense"
            variant="outlined"
            helperText={
              <>
                Obrigatório no mínimo 6 caracteres, sendo 1 letra maiúscula e 1
                caractere especial.
                <div>
                  <span id="message"></span>
                </div>
              </>
            }
            size="small"
            InputProps={{
              style: {
                borderRadius: '20px',
              },
            }}
            color="primary"
            placeholder="Confirme a sua nova senha"
            type={values.showPasswordConfirm ? 'text' : 'password'}
            required
            fullWidth
            onKeyUp={check}
            id="checkfinancialpassword"
            name="checkfinancialpassword"
            onChange={e => {
              setConfirmFinancial(e.target.value);
            }}
            autoFocus
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordConfirm}
                  onMouseDown={handleMouseDownPasswordConfirm}
                >
                  {values.showPasswordConfirm ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <IconButton
              disabled={disableButton}
              isLoading={loadButton}
              title="Confirmar"
              color="primary"
              style={{ backgroundColor: theme.palette.divider }}
              onClick={handleAbrirConta}
              aria-label="delete"
            >
              <Save />
            </IconButton>
          </Box>
        </form>
        <ToastContainer />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
