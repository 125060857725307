/* eslint react/prop-types: 0 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Container, ButtonComponent } from './styles';

const ConfirmButton = ({
  variant,
  color,
  disabled,
  children,
  width,
  isLoading,
  ...rest
}) => {
  return (
    <Container>
      <ButtonComponent
        width={width}
        variant={variant}
        color={color}
        disabled={disabled}
        isLoading={isLoading}
        {...rest}
      >
        {isLoading ? (
          <CircularProgress color="inherit" size={24} circleDisableShrink />
        ) : (
          children
        )}
      </ButtonComponent>
    </Container>
  );
};

export default ConfirmButton;
