export const NO_AUTH_ROUTES = [
  '/login/new-password/',
  '/login/new-password',
  '/new-register/',
  '/new-register',
  '/login/faq-login/',
  '/login/faq-login',
  '/login/confirm-email/',
  '/login/confirm-email',
  '/login/CadastrarSenha/',
  '/login/CadastrarSenha',
  '/login/reset',
];
