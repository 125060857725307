import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes/index';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './styles/theme';
import {
  createTheme,
  ThemeProvider,
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { ptBR } from '@material-ui/core/locale/';
import { generateApiApp, generateThemeDefault } from './theme/api.theme';
import { Box } from '@material-ui/core';
import KeycloakService from './auth';
import { NO_AUTH_ROUTES } from './auth/no-auth-routes';
import useToast from './styles/toast-style';

const generateClassName = createGenerateClassName({
  productionPrefix: 'solucoes',
  seed: 'login',
});

function Index() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { useStyles, themeApp, setThemeApp } = useToast();
  const classes = useStyles();

  useEffect(() => {
    KeycloakService.initKeycloak(setIsAuthenticated);
    generateThemeDefault();
    generateApiApp()
      .then(theme => {
        theme.palette.type = 'light';
        const customTheme = createTheme({ ...theme }, ptBR);
        setThemeApp(customTheme);
      })
      .catch(erro => console.log('main erro pegar tehema ', erro));
  }, []);

  const pathname = window.location.pathname?.replace(/\/$/, '');

  const isNoNeedAuth = NO_AUTH_ROUTES.includes(pathname);

  return isAuthenticated || isNoNeedAuth ? (
    <StylesProvider generateClassName={generateClassName}>
      {themeApp && (
        <React.StrictMode>
          <ChakraProvider theme={theme}>
            <ThemeProvider theme={themeApp}>
              <ToastContainer className={classes.customToast} />
              <Routes />
            </ThemeProvider>
          </ChakraProvider>
        </React.StrictMode>
      )}
    </StylesProvider>
  ) : (
    <Box
      alignItems="center"
      display="flex"
      height="100vh"
      justifyContent="center"
      width="100%"
    >
      <img
        style={{
          height: '6.75rem',
          maxHeight: '350px',
          maxWidth: '350px',
        }}
        alt=""
        src="https://storageappbeyondus.blob.core.windows.net/multisolucoes/tema/loadingbeyondbank.gif"
      />
    </Box>
  );
}
export default React.memo(Index);
