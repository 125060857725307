import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  // styles: {
  //     global: {
  //         body: {
  //             bg: 'gray.50',
  //             color: 'gray.900'
  //         }
  //     }
  // },
  initialColorMode: 'light',
  useSystemColorMode: false,
  colors: {
    teal: {
      500: '#990099',
      600: '#009900',
    },
  },
});
