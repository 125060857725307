import { api_multipague_configuracao } from '../services/api';

const themeDefault = require('./default.json');

require('./type-config-app.d');

/** @return {TypeConfigApi} */
export const getTheme = () => {
  const config = localStorage.getItem('app-config');
  return JSON.parse(config);
};

const token = localStorage.getItem('tkn-access');

/** @return {TypeConfigApi} */

export const generateApiApp = async () => {
  try {
    const { data } = await api_multipague_configuracao.get('/parametros/tema');
    const { json } = data;
    let theme = JSON.parse(json);
    if (!theme.hasOwnProperty('palette') && !theme.hasOwnProperty('config')) {
      theme = themeDefault;
    }
    localStorage.setItem('app-config', JSON.stringify(theme));
    return theme;
  } catch (error) {
    console.log('Error', error);
    localStorage.setItem('app-config', JSON.stringify(themeDefault));
    return themeDefault;
  }
};

export const generateThemeDefault = () => {
  localStorage.setItem('app-config', JSON.stringify(themeDefault));
  return themeDefault;
};
