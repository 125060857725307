import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PollIcon from '@material-ui/icons/Poll';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DvrIcon from '@material-ui/icons/Dvr';
import HomeIcon from '@material-ui/icons/Home';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Link from '@material-ui/core/Link';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

const cookies = new Cookies();
const permissionUser = cookies.get('roles');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    maxWidth: 360,
    backgroundColor: '#394C59',
    color: '#fff',
  },
  // menu: {
  //   paddingLeft: theme.spacing(4),
  // },
  menu: {
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
    color: '#fff',
  },
}));

export default function mainListItems() {
  const classes = useStyles();
  const history = useHistory();
  const [openConfig, setopenConfig] = React.useState(false);

  const handleClickConfig = () => {
    setopenConfig(!openConfig);
  };

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    history.push('/login?contexto=backoffice');
  }

  return (
    <div>
      <Link color="inherit" href="/backoffice/new-backoffice">
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </Link>
      {permissionUser == 'backoffice-admin' ? (
        <Link color="inherit" href="/backoffice/pendencias-abertura">
          <ListItem button>
            <ListItemIcon>
              <DvrIcon />
            </ListItemIcon>
            <ListItemText primary="Monitor" />
          </ListItem>
        </Link>
      ) : (
        <ListItem button disabled>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Monitor" />
        </ListItem>
      )}

      <Link color="inherit" href="/backoffice/Esteira">
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Esteira" />
        </ListItem>
      </Link>

      <Link color="inherit" href="/backoffice/pwd">
        <ListItem button>
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary="Senha Financeira" />
        </ListItem>
      </Link>
      <Link color="inherit" href="/backoffice/userlist">
        <ListItem button>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Gestão de Usuários" />
        </ListItem>
      </Link>
      {/* <Link color="inherit" href="/backoffice/userlist">
      <ListItem button>
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>
        <ListItemText primary="Listar Usuários" />
      </ListItem>
    </Link> */}

      <ListItem button className={classes.menu} onClick={handleClickConfig}>
        <ListItemIcon>
          <SettingsIcon style={{ fill: 'white' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography class="">Configurações</Typography>
        </ListItemText>
        {openConfig ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openConfig} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link href="/backoffice/eventos-lista">
            <ListItem button className={classes.menu}>
              <ListItemIcon>
                <SubdirectoryArrowRightIcon style={{ fill: 'white' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography class="">Eventos</Typography>
              </ListItemText>
            </ListItem>
          </Link>

          <Link href="/backoffice/bancos-lista">
            <ListItem button className={classes.menu}>
              <ListItemIcon>
                <SubdirectoryArrowRightIcon style={{ fill: 'white' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography class="">Bancos</Typography>
              </ListItemText>
            </ListItem>
          </Link>

          <Link href="/backoffice/associacao-contas-lista">
            <ListItem button className={classes.menu}>
              <ListItemIcon>
                <SubdirectoryArrowRightIcon style={{ fill: 'white' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography class="">Associação de Contas</Typography>
              </ListItemText>
            </ListItem>
          </Link>

          <Link href="/backoffice/associacao-bancos-lista">
            <ListItem button className={classes.menu}>
              <ListItemIcon>
                <SubdirectoryArrowRightIcon style={{ fill: 'white' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography class="">Associação de Bancos</Typography>
              </ListItemText>
            </ListItem>
          </Link>
        </List>
      </Collapse>

      <Link onClick={() => logout()}>
        <ListItem button>
          <ListItemIcon>
            <MeetingRoomIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </Link>
    </div>
  );
}
