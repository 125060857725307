import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { api_multipague_configuracao } from '../services/api';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Beyond. Todos os Direitos Reservados'}
      <Link color="inherit" href="#">
      BeyondBank
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => {
  console.log('theme do select', theme);
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    formControl: {
      margin: theme.spacing(0),
      width: '100%',
      minWidth: 120,
    },
  };
});

export default function SignIn() {
  const classes = useStyles();

  const [selectContas, setSelectContas] = useState('');
  const [contas, setContas] = useState('');

  const handleChangeSelect = event => {
    setSelectContas(event.target.value);
  };

  useEffect(() => {
    api_multipague_configuracao
      .get('/banco/parceiros')
      .then(response => {
        console.log('Select do cadastro ', response.data);

        const selectFilter = response.data;
        const aquiSelect = selectFilter.map(key => (
          <MenuItem key={key} value={key.id}>
            {key.nome}
          </MenuItem>
        ));
        setContas(aquiSelect);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Qual conta deseja acessar?
        </Typography>
        <form className={classes.form} noValidate>
          <FormControl
            variant="outlined"
            size="small"
            fullwidth
            className={classes.formControl}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // defaultValue={274}
              value={selectContas}
              required
              fullwidth
              onChange={handleChangeSelect}
            >
              {contas}
            </Select>
          </FormControl>
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Voltar
              </Link>
            </Grid>
            <Grid item>
              {/* <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link> */}
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
