import { Box } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const LoadingLoginPage = () => {
  const query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const isPermicao = localStorage.getItem('profileBackOffice');
  const isPermicaoEscrow = localStorage.getItem('administradorEscrow');

  if (isPermicao == 'true' && query.get('contexto') == 'backoffice') {
    window.location.href = '/backoffice';
  } else if (
    isPermicaoEscrow == 'true' &&
    query.get('contexto') == 'backoffice'
  ) {
    window.location.href = '/backoffice/monitor-aprovacao';
  } else {
    window.location.href = '/select-account';
  }
  return (
    <Box
      alignItems="center"
      display="flex"
      height="100vh"
      justifyContent="center"
      width="100%"
    >
      <img
        style={{
          height: '6.75rem',
          maxHeight: '350px',
          maxWidth: '350px',
        }}
        alt=""
        src="https://storageappbeyondus.blob.core.windows.net/multisolucoes/tema/loadingbeyondbank.gif"
      />
    </Box>
  );
};
